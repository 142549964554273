<div class="row report-card-list">
  <div class="col-lg-3 d-flex">
    <nb-card class="w-100 report-card">
      <nb-card-body class="overflow">
          <div class="report-card__half">
            <div class="subtitle">Sales</div>
            <h5>{{(report?.sales ? report?.sales : '0') | currency}} </h5>
          </div>
          <div class="report-card__half">
            <div style="position:relative; left:-43%;"><img src="/assets/images/revenue.png" class="img-fluid" style="height:190px; width:100px; "/></div>
            <div style="position:absolute;"><div class="subtitle" >Revenue</div>
            <h5>{{(report?.revenue ? report?.revenue : '0') | currency}}</h5></div>
          </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-lg-9">
    <div class="row">
      <div class="col-6 col-lg-4 d-flex">
        <nb-card class="report-card w-100">
          <nb-card-body>
            <i class="fas fa-paper-plane fa-2x" style="padding-top:5px;"></i>
            <div class="subtitle">Incoming Leads</div>
            <h5>{{clientCount? clientCount : '0'}}</h5>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-6 col-lg-4 d-flex">
        <nb-card class="report-card w-100">
          <nb-card-body>
            <img src="/assets/images/evaluation.png" class="img-fluid" style="width:35px; height:35px"/>
              <br>
              <div class="subtitle">Evaluations Completed</div>
              <h5>{{report?.evaluations_completed ? report?.evaluations_completed : '0'}}</h5>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-6 col-lg-4 d-flex">
        <nb-card class="report-card w-100">
          <nb-card-body>
            <i class="fas fa-paperclip fa-2x" style="color:#7EBCCB; padding-top:5px;"></i>
            <div class="subtitle">Quotes Sent</div>
            <h5>{{report?.quotes_sent ? report?.quotes_sent : '0'}}</h5>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-6 col-lg-4 d-flex">
        <nb-card class="report-card w-100">
          <nb-card-body>
            <i class="fas fa-check-circle fa-2x" style="color:#F0C21A;"></i>
            <div class="subtitle">Projects Completed</div>
            <h5>{{report?.projects_completed ? report?.projects_completed : '0'}}</h5>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-6 col-lg-4 d-flex">
        <nb-card class="report-card w-100">
          <nb-card-body>
            <i class="fas fa-file-invoice-dollar fa-2x" style="color:#641143;"></i>
              <br>
              <div class="subtitle">Project Closed</div>
              <h5>{{report?.projects_closed ? report?.projects_closed : '0'}}</h5>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-6 col-lg-4 d-flex">
        <nb-card class="report-card w-100">
          <nb-card-body>
            <i class="fas fa-file-signature fa-2x" style="color:#B9D7E3;"></i>
            <div class="subtitle">Signed Contracts</div>
            <h5>{{report?.signed_contracts ? report?.signed_contracts : '0'}}</h5>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</div>
