import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClientData, Clients } from '../data/clients';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends ClientData {
  private subject = new Subject<any>();



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  private apiToken = '';

  constructor(private httpClient: HttpClient, private authService: NbAuthService,
    private toastrService: NbToastrService,) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  private _refreshNeeded$ = new Subject<void>();

  get refreshNeeded$(){
    return this._refreshNeeded$;
  }

  getClients(): Observable<Clients[]> {
    return this.httpClient.get<Clients[]>(environment.apiBaseUrl + `/accounts/client/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getClientById(id: number): Observable<Object> {
    let client =  this.httpClient.get<Clients[]>(environment.apiBaseUrl + `/accounts/client/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    return client;
  }

  getClientByFranchiseeId(id): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/accounts/client/?franchisee=${id}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getClientByFranchiseeIdAndPage(id, pageNumber): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/accounts/client/?franchisee=${id}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getClientByFranchiseeIdAndNameAndPage(id, name, pageNumber): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/accounts/client/?franchisee=${id}&search=${name}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getClientCount(id): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/accounts/client/?franchisee=${id}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getSales(): Observable<Clients[]> {
    return this.httpClient.get<Clients[]>(environment.apiBaseUrl + `/accounts/client/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }



  addClient(client): Observable<Object> {
    return this.httpClient
    .post(`${environment.apiBaseUrl}/accounts/client/`,
    client,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
       catchError(this.handleError)
      );
  }

  editClientById(client:any, id: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    const clientData =  this.httpClient
    .put(`${environment.apiBaseUrl}/accounts/client/${id}/`,
    client,
      options,
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
       catchError(this.handleError)
      );
    this.sendUpdateEvent();
    return clientData;
  }


  deleteClient(id): Observable<Object> {

    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    const clientData = this.httpClient
    .delete(`${environment.apiBaseUrl}/accounts/client/${id}/`,
      options,)
      .pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
        catchError(this.handleError)
      );

      this.sendUpdateEvent();
      return clientData;

  }


  sendUpdateEvent() {
    this.subject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }


}

