
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { FranchiseeDetailsService } from '../../../@core/api/franchisee-details.service';
import { UserService } from '../../../@core/api/users.service';
import { FranchisorReport, FranchisorReportData } from '../../../@core/data/franchisor-report';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentUser } from '../../../@core/data/users';
import { ProjectStatusService } from '../../../@core/api/project-status.service';
import { Router } from '@angular/router';
import { ProjectList } from '../../../@core/data/project-status';
@Component({
  selector: 'ngx-reports-franchise',
  styleUrls: ['./reports-franchise.component.scss'],
  templateUrl: './reports-franchise.component.html',
})
export class ReportsFranchiseComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  franchisorReport: FranchisorReport;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    scrollwheel: false,
    disableDoubleClickZoom: true,
    // mapTypeId: 'hybrid',
    maxZoom: 15,
    minZoom: 8,
    styles: [
      { elementType: "geometry", stylers: [{ color: "#D5E5E0" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#FFFFFF" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#8CB7DF" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#677889" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#677889" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#BDEADC" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6E7F90" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#FFFFFF" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#8CB7DF" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8CB7DF" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#F2CE4B" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#F2CE4B" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8CB7DF" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#FFFFFF" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8CB7DF" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#8CB7DF" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8CB7DF" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#8CB7DF" }],
      },
    ],
  };
  markers = [];
  infoContent = '';
  projectList: ProjectList;
  role: string;
  apiLoaded: boolean;

  constructor(private franchisorReportService: FranchisorReportData,
    private franchiseService: FranchiseeDetailsService,
    private userService: UserService,
    private projectService: ProjectStatusService,
    private router: Router,
  ) {
    this.apiLoaded = Boolean(window.google && window.google.maps);
  }

  ngOnInit() {
    this.franchisorReportService.getFranchisorReportData()
      .pipe()
      .subscribe((data) => {
        this.franchisorReport = data;
      });

    this.userService.getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      // Current user
      .subscribe((user: CurrentUser) => {
        this.franchiseService.getFranchiseeDetails(user['franchisee id'])
          // Franchisee
          .subscribe(franchisee => {
            // Project
            const requests = franchisee['project_set'].map(id => this.projectService.getAProjectList(id));
            if (requests.length > 0) {
              forkJoin(requests).subscribe((projectList) => {
                projectList.forEach((project) => {
                  this.addMarker(project);
                })
              });
            }
          });
      }); // End Current User

    this.center = {
      lat: 45.4479299,
      lng: -75.6852912,
    };

  }

  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--;
  }

  addMarker(project) {

    this.markers.push({

      position: {
        lat: project?.address?.latitude,
        lng: project?.address?.longitude,
      },
      label: {
        color: '#677889',
        text: project?.client?.first_name + ' ' + project?.client?.last_name,
      },
      title: project.id,
      options: {
        animation: google.maps.Animation.BOUNCE,
        icon: "/assets/images/location.svg",
      },
    });
  }

  openInfo(marker: MapMarker, content) {
    this.router.navigateByUrl(`/pages/project-status/${content}`);
  }

}
