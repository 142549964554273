import { Component } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start [collapsedBreakpoints]="['xs']">
        <a class="logo d-none d-xl-block" href="/"><img src="/assets/images/logo.png" class="img-fluid" /></a>
        <div class="menu-sidebar__toggler">
          <a href="#" class="sidebar-toggle d-xl-none" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
            (click)="toggleSidebar()">
            <nb-icon icon="menu-outline"></nb-icon>
          </a>
        </div>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet" class="main-content"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  public constructor(
    private sidebarService: NbSidebarService
  ){};

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }
}
