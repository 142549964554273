import { Observable } from 'rxjs';

export interface MarketingTag {
  id: number;
  author: number;
  documents_set: any;
  created: string;
  updated: string;
  tag_name: string;
}

export abstract class MarketingTagData {
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
  abstract getMarketingTags(url): Observable<Object>;
  abstract addTag(data);
}
