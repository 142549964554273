import { Observable } from 'rxjs';

export interface Franchisees {
  id: number;
  active: boolean;
  business_tax_number: number;
  email_verified: boolean;
  office_number: string;
  phone_number: string;
  profile_pic_url: string;
  profile_picture: string;
  user: [{
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    username: string;
  }];
  address: [{
    city: string;
    id: number;
    latitude: string;
    longitude: string;
    province: string;
    street_name: string;
    street_number: string;
    unit_number: string;
  }];
  address2: string;
  business_contact: [{
    email_address: string;
    phone_number: string;
    address2: string;
  }];
  emergency_contact: [{
    id: number;
    phone_number: string;
    email_address: string;
    name: string;
    address2: string;
  }];
  province: [{
    id: number;
    name: string;
    tax_type: string;
    pst: number;
    gst: number;
    hst: number;
  }];
  employee_set: [],
  invoices_set: [{
    client: [{
      first_name;
      last_name;
    }];
    date_acquired;
    id;
    amount_due;
    HST;
  }],
  billing_set: [],
  client_set: [],
  quote_set: [],
  project_set: [],
  estimateinfo_set: [],
}

export abstract class FranchiseeData {
  abstract getFranchisees(): Observable<Object>;
  abstract getFranchiseeById(id): Observable<Object>;
  abstract createFranchisee(data): Observable<Object>;
  abstract updateFranchisees(data);
  abstract getProvinceList(): Observable<Object>;
  abstract getProvinceById(id): Observable<Object>;
}
