import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { DashboardService } from '../../../@core/api/dashboard.service';
import { UserService } from '../../../@core/api/users.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrentUser } from '../../../@core/data/users';

@Component({
  selector: 'ngx-list',
  templateUrl: 'franchisees.component.html',
  styleUrls: ['franchisees.component.scss'],
})
export class FranchiseesComponent implements OnInit, OnChanges {
  @Input() period = '';
  private destroy$: Subject < void > = new Subject < void > ();
  user: CurrentUser;
  franchisees: any;
  constructor(private dashboardService: DashboardService,
    private userService: UserService) { }

  ngOnInit() {
    // 1. Get current user
    this.userService.getCurrentUser()
    .pipe(takeUntil(this.destroy$))
    .subscribe((user: any) => {
      this.user = user;      
      if(this.user){
        if(this.user.role === 'FRANCHISOR'){
          this.getFranchiseeReport();
        }
      }
    });
  }

  ngOnChanges() {
    if(this.user){
      if(this.user.role === 'FRANCHISOR'){
        this.getFranchiseeReport();
      }
    }
  }

  getFranchiseeReport(){
    this.dashboardService.getFranchiseeReportListbyPeriod(this.period)
    .pipe()
    .subscribe((data) => {
      this.franchisees = data['results'];
    });
  }

}
