
<ng-container *ngIf="role !== 'EMPLOYEE' && loaded">
<div *ngIf="accessChecker.isGranted('view', 'dashboard-reports')">



  <div class="row">
    <div class="col-md-9">
      <div class="imageTwinkle"><img src="/assets/images/twinkle_star.png" class="img-fluid" /><h1 class="titleLeft">DASHBOARD</h1></div>
      <h2 class="title">Reports</h2>
      <ngx-reports [period]='selectedPeriod'></ngx-reports>
      <ngx-list *ngIf="role === 'FRANCHISOR'" [period]='selectedPeriod'></ngx-list>
      <ngx-reports-franchise *ngIf="role === 'FRANCHISEE'"></ngx-reports-franchise>
    </div>
    <div class="col-md-3">
      <h6>Selected range: <br>{{ range.start | date }} - {{ range.end | date }}</h6>
          <nb-calendar-range [(range)]="range" (rangeChange)="getRangeDate($event)"></nb-calendar-range>
    </div>
  </div>


</div>
</ng-container>
