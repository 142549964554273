import { Observable, throwError, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EstimateData } from '../data/estimates';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { NbIconConfig, NbToastrService } from '@nebular/theme';


@Injectable({
  providedIn: 'root',
})
export class EstimateService extends EstimateData {
  private apiToken = '';
  private subject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }

    return throwError('Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.apiToken = token['token'];
      }
    });
  }

  getEstimates(): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/estimateinfo/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getEstimateInfoById(id): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/estimateinfo/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getEstimateInfoByFranchiseeId(id, pageNumber): Observable<Object> {
    let estimates =  this.httpClient
      .get(`${environment.apiBaseUrl}/operations/estimateinfo/?franchisee=${id}&page=${pageNumber}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
      // this.sendUpdateEvent();
      return estimates;
  }

  getEstimateInfoByFranchiseeIdAndNameAndPageNumber(id, name, pageNumber): Observable<Object> {
    let estimates =  this.httpClient
      .get(`${environment.apiBaseUrl}/operations/estimateinfo/?franchisee=${id}&search=${name}&page=${pageNumber}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
      // this.sendUpdateEvent();
      return estimates;
  }

  getEstimateById(id): Observable<Object> {
    let estimates =  this.httpClient
      .get(`${environment.apiBaseUrl}/operations/estimates/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
      this.sendUpdateEvent();
      return estimates;
  }

  getEvaluationInfoById(id): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/evaluation/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getEstimateDetailsById(id): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/estimatedetails/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getServices(url): Observable<Object> {
    return this.httpClient
      .get(url, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getToolInventory(url): Observable<Object> {
    return this.httpClient
    .get(url, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getToolEstimates(url): Observable<Object> {
    return this.httpClient
      .get(url, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getClients(): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/accounts/client/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getClientById(id: number): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/accounts/client/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getWorkOrder(id: number): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/workorder/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  updateEstimateById(estimate: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/estimateinfo/${id}/`,
        estimate,
        options,
      )
      .subscribe(
        (data) => {
          // const iconConfig: NbIconConfig = {
          //   icon: 'checkmark-circle-2-outline',
          //   pack: 'eva',
          // };
          // this.toastrService.show('', `Estimates successfully updated.`, {
          //   status: 'success',
          //   icon: iconConfig,
          // });
        },
        (err) => {
          this.handleError;
        },
      );
  }

  updateEstimateDetailsById(estimate: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/estimates/${id}/`,
        estimate,
        options,
      )
      .subscribe(
        (data) => {
        },
        (err) => {
          this.handleError;
        },
      );
  }

  updateEvaluationInfo(evaluation: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/evaluation/${id}/`,
        evaluation,
        options,
      )
      .subscribe(
        (data) => {

        },
        (err) => {
          const iconConfig: NbIconConfig = {
            icon: 'alert-circle-outline',
            pack: 'eva',
          };
          this.toastrService.show('', `Error updating estimate.`, {
            status: 'danger',
            icon: iconConfig,
          });

          this.handleError;
        },
      );
  }

  updateToolInventory(evaluation: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    return this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/toolinventorydetails/${id}/`,
        evaluation,
        options,
      )
  }

  updateEvaluationCalculation(evaluation: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    return this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/evaluationcalculation/${id}/`,
        evaluation,
        options,
      )
  }

  updateWorkOrder(evaluation: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/workorder/${id}/`,
        evaluation,
        options,
      )
      .subscribe(
        (data) => {
        },
        (err) => {
          this.handleError;
        },
      );
  }

  updateLabour(evaluation: any, id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    return this.httpClient
      .patch(
        `${environment.apiBaseUrl}/operations/estimatedetails/${id}/`,
        evaluation,
        options,
      )
  }

  addEvaluation(data: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const evalCalc =  this.httpClient.post(`${environment.apiBaseUrl}/operations/evaluation/`,
      data,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return evalCalc;
  }


  addEvaluationCalculation(data: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const evalCalc =  this.httpClient.post(`${environment.apiBaseUrl}/operations/evaluationcalculation/`,
      data,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return evalCalc;
  }

  addLabour(data: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const labour =  this.httpClient.post(`${environment.apiBaseUrl}/operations/estimatedetails/`,
      data,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return labour;
  }

  addToolInventory(data: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const toolinventory =  this.httpClient.post(`${environment.apiBaseUrl}/operations/toolinventorydetails/`,
      data,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      return toolinventory;
  }

  addWorkOrder(data: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const workorder =  this.httpClient.post(`${environment.apiBaseUrl}/operations/workorder/`,
      data,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return workorder;
  }

  addEstimates(estimate: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const workorder =  this.httpClient.post(`${environment.apiBaseUrl}/operations/estimateinfo/`,
    estimate,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return workorder;
  }

  addLabourEstimates(estimate: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const workorder =  this.httpClient.post(`${environment.apiBaseUrl}/operations/estimates/`,
    estimate,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return workorder;
  }

  estimateUploadPhoto(photo: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const workorder =  this.httpClient.post(`${environment.apiBaseUrl}/operations/estimateuploads/`,
      photo,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return workorder;
  }

  evaluationUploadPhoto(photo: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    const workorder =  this.httpClient.post(`${environment.apiBaseUrl}/operations/evaluationuploads/`,
      photo,
      options,
      ).pipe(
        catchError(this.handleError),
      );
      this.sendUpdateEvent();
      return workorder;
  }

  removeEvaluationCalculation(id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .delete(
        `${environment.apiBaseUrl}/operations/evaluationcalculation/${id}/`,
        options,
      )
      .subscribe(
        (data) => {

        },
        (err) => {
          const iconConfig: NbIconConfig = {
            icon: 'alert-circle-outline',
            pack: 'eva',
          };
          this.toastrService.show('', `Error updating estimate.`, {
            status: 'danger',
            icon: iconConfig,
          });

          this.handleError;
        },
      );
  }

  removeToolInventory(id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .delete(
        `${environment.apiBaseUrl}/operations/toolinventorydetails/${id}/`,
        options,
      )
      .subscribe(
        (data) => {

        },
        (err) => {
          const iconConfig: NbIconConfig = {
            icon: 'alert-circle-outline',
            pack: 'eva',
          };
          this.toastrService.show('', `Error updating tool inventory.`, {
            status: 'danger',
            icon: iconConfig,
          });

          this.handleError;
        },
      );
  }

  removeWorkOrder(id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .delete(
        `${environment.apiBaseUrl}/operations/workorder/${id}/`,
        options,
      )
      .subscribe(
        (data) => {

        },
        (err) => {
          const iconConfig: NbIconConfig = {
            icon: 'alert-circle-outline',
            pack: 'eva',
          };
          this.toastrService.show('', `Error updating work order.`, {
            status: 'danger',
            icon: iconConfig,
          });

          this.handleError;
        },
      );
  }

  removeLabour(id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .delete(
        `${environment.apiBaseUrl}/operations/estimatedetails/${id}/`,
        options,
      )
      .subscribe(
        (data) => {

        },
        (err) => {
          const iconConfig: NbIconConfig = {
            icon: 'alert-circle-outline',
            pack: 'eva',
          };
          this.toastrService.show('', `Error updating work order.`, {
            status: 'danger',
            icon: iconConfig,
          });

          this.handleError;
        },
      );
  }

  removeEstimatePhoto(id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .delete(
        `${environment.apiBaseUrl}/operations/estimateuploads/${id}/`,
        options,
      )
      .subscribe(
        (data) => {
        },
        (err) => {
          this.handleError;
        },
      );
  }

  removeEvaluationPhoto(id: number) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient
      .delete(
        `${environment.apiBaseUrl}/operations/evaluationuploads/${id}/`,
        options,
      )
      .subscribe(
        (data) => {
        },
        (err) => {
          this.handleError;
        },
      );
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  sendUpdateEvent() {
    this.subject.next();
  }


}
