import { Observable, BehaviorSubject  } from 'rxjs';

export interface CurrentUser {
  email: string;
  username: string;
  role: string;
  id: number;
  job_title: string;
}

export abstract class UserData {
  private userSource = new BehaviorSubject<Object>(null);
  currentUserRole = this.userSource.asObservable();

  abstract getCurrentUser(): Observable<Object>;
  abstract logout();

  setCurrentRole(role: string) {
    this.userSource.next(role);
  }
}
