import { Observable } from 'rxjs';

export interface InvoiceInfo {
  id: number;
  due_date: string;
  updated: string;
  invoice_date: string;
  client: number;
  amount_due: string;
  change_order: any;
  estimate: number;
  franchisee: string;
  project: string;
  invoice_title: string;
  paid_via: string;
}

export interface Invoice {
  id: number;
  title: string;
  description: string;
  rate: number;
  hours: number;
  amount: string;
}

export interface PaymentInfo {
  id: number;
  client: number;
  invoice: number;
  payment_date: string;
  payment_type: string;
  paid_via: string;
  amount: string;
  external_payment_id: string;
}

export abstract class InvoiceData {
  abstract getInvoice(url): Observable<Object>;
  abstract getInvoiceById(id): Observable<Object>;
  abstract getInvoiceByFranchiseeId(id, pageNumber): Observable<Object>;
  abstract editInvoiceById(id, data): Observable<Object>;
  abstract deleteInvoiceById(id): Observable<Object>;
  abstract addInvoice(data): Observable<Object>;
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
  abstract getRecordPaymentByInvoiceId(id): Observable<Object>;
  abstract addRecordPayment(payment): Observable<Object>;
  abstract editRecordPaymentByInvoiceId(id, payment): Observable<Object>;
  abstract addPaymentChange(payment): Observable<Object>;
}
