<div class="header-container">
  <a class="logo d-lg-none" href="/"><img src="/assets/images/logo.png" class="img-fluid" /></a>
  <nb-actions size="small">
    <nb-action class="user-action" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true">
      <button *ngIf = "jobTitle === 'manager'" nbButton class="btn dashboard-btn" [nbContextMenu]="viewMenu" name="{{user?.first_name}} {{user?.last_name}}"
      nbContextMenuTag="view-context-menu"><nb-icon icon="home-outline"></nb-icon>Dashboard Type</button>
      <button nbButton class="btn" [nbContextMenu]="userMenu" name="{{user?.first_name}} {{user?.last_name}}"
      nbContextMenuTag="user-context-menu"><nb-icon icon="person"></nb-icon>{{user?.first_name}} {{user?.last_name}}</button>
    </nb-action>
  </nb-actions>
</div>
