<div class="lists row">
  <div class="col-md-12">
    <nb-card class="list-card" size="small">
      <nb-card-header>Franchisees</nb-card-header>
      <nb-card-body>
        <nb-list>
          <nb-list-item *ngFor="let franchisee of franchisees">
            <div class="franchisee-container">
              <!-- <div class="franchisee-picture"></div> -->

              <div class="info-container">
                <div>{{ franchisee?.user?.first_name }} {{ franchisee?.user?.last_name }}</div>
                <div *ngIf="franchisee?.address2"><small><i class="fas fa-map-marker-alt"></i>{{ franchisee?.address2}}</small></div>
              </div>
            </div>

            <div class="d-block d-md-none">
              <div class="franchisee-list-stats">
                <div class="franchisee-list-stat">
                  <span>+2% <nb-icon icon="diagonal-arrow-right-up-outline"></nb-icon></span>
                </div>
                <div>
                  <span class="fa fa-chevron-circle-down"></span>
                </div>
              </div>
            </div>

            <div class="d-none d-md-block">
              <div class="franchisee-list-stats">
                <div class="franchisee-list-stat">
                  <span>+{{franchisee?.gross_profit_percentage}}% <nb-icon icon="diagonal-arrow-right-up-outline"></nb-icon></span>
                </div>
                <div class="franchisee-list-stat">
                  <div>{{franchisee?.closed_deals | number : '1.2-2'}}%</div>
                  <span class="caption">Closed Deals</span>
                </div>
                <div class="franchisee-list-stat">
                  <div>{{franchisee?.revenue | currency}}</div>
                  <span class="caption">Revenue</span>
                </div>
                <div class="franchisee-list-stat">
                  <div>{{franchisee?.sales | currency}}</div>
                  <span class="caption">Sales</span>
                </div>
                <!-- <div>
                  <span class="fa fa-chevron-circle-down"></span>
                </div> -->
              </div>
            </div>
          </nb-list-item>
        </nb-list>
      </nb-card-body>
    </nb-card>
  </div>
</div>
