import { Observable } from 'rxjs';


export interface EstimateInfo {
  id: number;
  agreement_date: string;
  agreement_terms: string;
  business_tax_number: string;
  client: {
    id: number;
  };
  currency: string;
  estimate_title: string;
  expiry_date: string;
  franchisee: number;
  notes: string;
  poso: string;
  status: string;
  tax_rate: number;
  total_amount: number;
  work_order: [{
    id: number;
    title: string;
    description: string;
  }];
  evaluation_set: [];
  estimates_set: [];
}

export interface Estimates {
  id: any;
  notes: string;
  photos: any;
  total_amount: number;
  estimate_details: [];
  tax_rate: number;
  estimate_info: number;
}

export interface EstimateDetails {
  id: number;
  title: string;
  description: string;
  amount: number;
  client_viewable: boolean;
}

export interface Evaluation {
  id: any;
  photos: any;
  evaluation_calculations: [{
    category: string;
    id: number;
    description: string;
    quantity: number;
    rate: number;
    title: string;
  }];
  evaluation_tool_inventories: [{
    id: number;
    title: string;
    tool_inventory: number;
    quantity: number;
    description: string;
  }];
  notes: string;
  total_amount: number;
}



export abstract class EstimateData {
  abstract getEstimates(): Observable<Object>;
  abstract getEstimateById(id): Observable<Object>;
  abstract getEstimateInfoById(id): Observable<Object>;
  abstract getEstimateInfoByFranchiseeId(id, pageNumber): Observable<Object>;
  abstract getEstimateInfoByFranchiseeIdAndNameAndPageNumber(id, name, pageNumber): Observable<Object>;
  abstract getEvaluationInfoById(id): Observable<Object>;
  abstract getEstimateDetailsById(id): Observable<Object>;
  abstract getToolInventory(url): Observable<Object>;
  abstract getToolEstimates(url): Observable<Object>;
  abstract getServices(url): Observable<Object>;
  abstract getClients(): Observable<Object>;
  abstract getWorkOrder(id): Observable<Object>;
  abstract getClientById(id): Observable<Object>;
  abstract addEstimates(data): Observable<Object>;
  abstract addLabourEstimates(data): Observable<Object>;
  abstract addToolInventory(data): Observable<Object>;
  abstract addEvaluation(data): Observable<Object>;
  abstract addEvaluationCalculation(data): Observable<Object>;
  abstract addWorkOrder(data): Observable<Object>;
  abstract addLabour(data): Observable<Object>;
  abstract estimateUploadPhoto(data): Observable<Object>;
  abstract evaluationUploadPhoto(data): Observable<Object>;
  abstract updateEstimateById(data, id);
  abstract updateEstimateDetailsById(data, id);
  abstract updateEvaluationInfo(data, id);
  abstract updateEvaluationCalculation(data, id);
  abstract updateToolInventory(data, id);
  abstract updateWorkOrder(data, id);
  abstract updateLabour(data, id);
  abstract removeEvaluationCalculation(id);
  abstract removeToolInventory(id);
  abstract removeWorkOrder(id);
  abstract removeLabour(id);
  abstract removeEstimatePhoto(id);
  abstract removeEvaluationPhoto(id);
  abstract getUpdateEvent(): Observable<Object>;
}
