import { Observable } from 'rxjs';

export interface Document {
  id: number;
  author: number;
  tags: any;
  created: string;
  updated: string;
  file: string;
}

export abstract class DocumentData {
  abstract selectedTag: number;
  abstract documents: any;
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent(msg);
  abstract getDocuments(url): Observable<Object>;
  abstract getDocumentsById(url): Observable<Object>;
  abstract addDocuments(data, index);
  abstract deleteDocument(id);
}
