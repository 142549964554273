import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FranchisorReport, FranchisorReportData } from '../data/franchisor-report';

@Injectable()
export class FranchisorReportService extends FranchisorReportData {

  private franchisorReport: FranchisorReport = {
    sales: '$12,390',
    revenue: '$1,000',
  };

  getFranchisorReportData(): Observable<FranchisorReport> {
    return observableOf(this.franchisorReport);
  }
}
