

<div class="franchise-status" *ngIf="apiLoaded">
  <h2 class="title">Locations</h2>
  <google-map
    height="500px"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
  >
    <map-marker
      #markerElem
      *ngFor="let marker of markers"
      [position]="marker.position"
      [label]="marker.label"
      [options]="marker.options"
      (mapClick)="openInfo(markerElem, marker.title)"
    >
    </map-marker>

    <map-info-window>{{ infoContent }}</map-info-window>
  </google-map>
</div>



