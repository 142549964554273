import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { UserService } from './users.service';
import { ProjectStatusData } from '../data/project-status';

@Injectable({
  providedIn: 'root',
})
export class ProjectStatusService extends ProjectStatusData {
  private apiToken = '';
  private projectStatusSubject = new Subject<any>();
  user: UserService;
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  private _refreshNeeded$ = new Subject<void>();

  get refreshNeeded$(){
    return this._refreshNeeded$;
  }

  getEmployees(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/employee/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getAllProjectList(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/project/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }


  getProjectsByFranchiseeId(id, pageNumber): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/project/?franchisee=${id}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getProjectsByFranchiseeIdAndNameAndPageNumber(id, name, pageNumber): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/project/?franchisee=${id}&search=${name}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }


  getAProjectList(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/project/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getProjectChecklist(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/projectchecklist/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getProjectChecklistById(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/projectchecklist/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getProjectChecklistQuestions(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/projectchecklistquestion/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getProjectStatus(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/projectchecklistonproject/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  addProjectStatus(status): Observable<Object> {

    const project =  this.httpClient.post(`${environment.apiBaseUrl}/operations/projectchecklistonproject/`,
    status,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return project;
  }

  updateProjectStatus(id, projectQuestions): Observable<Object> {

    const project =  this.httpClient.put(`${environment.apiBaseUrl}/operations/projectchecklistonproject/${id}/`,
    projectQuestions,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return project;
  }

  updateProject(id, projectData): Observable<Object> {

    const project =  this.httpClient.patch(`${environment.apiBaseUrl}/operations/project/${id}/`,
    projectData,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return project;
  }

  deleteProjectbyID(id): Observable<Object> {

    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    const projectData = this.httpClient
    .delete(`${environment.apiBaseUrl}/operations/project/${id}/`,
      options,)
      .pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
        catchError(this.handleError)
      );

      this.sendUpdateEvent();
      return projectData;

  }



  getProjectExpense(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/projectexpense/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getChangeOrder(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/changeorder/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  addExpense(expense): Observable<Object> {
    return this.httpClient.post(`${environment.apiBaseUrl}/operations/projectexpense/`,
    expense,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  updateExpense(id, expense): Observable<Object> {

    const project =  this.httpClient.put(`${environment.apiBaseUrl}/operations/projectexpense/${id}/`,
    expense,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return project;
  }

  removeExpense(id): Observable<Object> {

    const project =  this.httpClient.delete(`${environment.apiBaseUrl}/operations/projectexpense/${id}/`,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return project;
  }

  addChangeOrder(changeorder): Observable<Object> {
    return this.httpClient.post(`${environment.apiBaseUrl}/operations/changeorder/`,
    changeorder,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }


  addProject(project): Observable<Object> {
    return this.httpClient.post(`${environment.apiBaseUrl}/operations/project/`,
    project,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  updateChangeOrder(id, expense): Observable<Object> {

    const changeorder =  this.httpClient.put(`${environment.apiBaseUrl}/operations/changeorder/${id}/`,
    expense,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return changeorder;
  }

  removeChangeOrder(id): Observable<Object> {

    const changeorder =  this.httpClient.delete(`${environment.apiBaseUrl}/operations/changeorder/${id}/`,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

    this.sendUpdateEvent();
    return changeorder;
  }

  sendUpdateEvent() {
    this.projectStatusSubject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.projectStatusSubject.asObservable();
  }


}
