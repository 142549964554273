import { Observable } from 'rxjs';

export interface Employee {
  id: number;
  user: object;
  status: string;
  position: string;
  job_title: string;
  onboarding_stage: number;
  address: string;
  address2: string;
  phone_number: string;
  email_verified: boolean;
  annual_salary: number;
  gender: string;
  job_start_date: string;
  job_end_date: string;
  completed_onboarding: boolean;
  profile_picture: string;
  date_of_birth: string;
  checklist: any;
}

export abstract class EmployeeData {
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
  abstract getEmployees(): Observable<Object>;
  abstract getEmployeeById(id): Observable<Object>;
  abstract getEmployeeByFranchiseeId(id): Observable<Object>;
  abstract getEmployeeByFranchiseeIdandPage(id, pageNumber): Observable<Object>;
  abstract addEmployee(data);
  abstract getOnboardingSteps(): Observable<Object>;
  abstract updateEmployee(id, employee): Observable<Object>;
}
