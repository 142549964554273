import { Observable } from 'rxjs';

export interface StartupCost {
  id: number;
  title: string;
  description: string;
  price: number;
  currency: string;
  is_standard: boolean;
}

export interface MonthlyCost {
  id: number;
  title: string;
  description: string;
  price: number;
  currency: string;
  is_standard: boolean;
}

export abstract class StartupMonthlyCostsData {
  abstract getStartupCost(): Observable<Object>;
  abstract getMonthlyCost(): Observable<Object>;
}
