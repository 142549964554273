import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbAccessChecker } from '@nebular/security';
import { tap } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private router: Router,
    public accessChecker: NbAccessChecker,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['auth/login']);
        } else {
          if (
            route.data &&
            route.data['permission'] &&
            route.data['resource']
          ) {
            this.accessChecker
              .isGranted(route.data['permission'], route.data['resource'])
              .subscribe((granted) => {
                if (granted) {
                  document.body.classList.add('loggedin');
                  return true;
                } else {
                  this.router.navigate(['auth/login']);
                  
                }
              });
          } else {
            this.router.navigate(['auth/login']);
          }
        }
      }),
    );
  }
}
