import { Observable } from 'rxjs';

export interface ProjectList {
  id: number;
  franchisee: number;
  status: string;
  project_start_date: string;
  project_end_date: string;
  description: string;
  client: [{
    first_name: string;
    last_name: string;
  }];
  address: [{
    city: string;
    id: number;
    latitude: string;
    longitude: string;
    province: string;
    street_name: string;
    street_number: string;
    unit_number: string;
  }];
  estimate: [{
    total: string;
    subtotal: string;
  }];
  manager: [{
    user: [{
      first_name: string;
      last_name: string;
    }],
  }];
  employees: [{
    id: number,
    user: [{
      first_name: string;
      last_name: string;
    }],
  }];
  changeorder_set: [{
    title: string;
    description: string;
    amount: number;
  }];
  work_order: [{
    title: string;
    description: string;
  }];
  notes: string;
  projectexpense_set: [];
  invoices_set: [{

  }];
  evaluation: [{
    total_amount;
  }];
  projectchecklistonproject_set: [{
    id: number;
    project_checklist: [];
    project_question: [];
  }];
  total_revenue: number;
}

export interface ProjectChecklist {
  id: number;
  title: string;
  description: string;
  projectchecklistquestion_set: [];
}

export interface ProjectChecklistQuestions {
  id: number;
  question: string;
  project: string;
  project_checklist: string;
}

export interface ProjectStatus {
  id: number;
  project_id: number;
  project_questions: [];
  project_checklist: [];
}

export interface ProjectExpense {
  id: number;
  name: number;
  amount: number;
}

export interface ProjectChangeOrder {
  id: number;
  title: number;
  description: number;
  amount: number;
}


export abstract class ProjectStatusData {
  abstract getEmployees(): Observable<Object>;
  abstract getAProjectList(id): Observable<Object>;
  abstract getAllProjectList(): Observable<Object>;
  abstract getProjectsByFranchiseeId(id, pageNumber): Observable<Object>;
  abstract getProjectsByFranchiseeIdAndNameAndPageNumber(id, name, pageNumber): Observable<Object>;
  abstract getProjectChecklist(): Observable<Object>;
  abstract getProjectChecklistById(id): Observable<Object>;
  abstract getProjectChecklistQuestions(): Observable<Object>;
  abstract getProjectStatus(id): Observable<Object>;
  abstract getProjectExpense(id): Observable<Object>;
  abstract getChangeOrder(id): Observable<Object>;
  abstract addExpense(id): Observable<Object>;
  abstract addChangeOrder(id): Observable<Object>;
  abstract addProject(data): Observable<Object>;
  abstract updateProjectStatus(id, projectQuestions): Observable<Object>;
  abstract updateProject(id, project): Observable<Object>;
  abstract deleteProjectbyID(id): Observable<Object>;
  abstract addProjectStatus(data): Observable<Object>;
  abstract updateExpense(id, expense): Observable<Object>;
  abstract updateChangeOrder(id, expense): Observable<Object>;
  abstract removeExpense(id): Observable<Object>;
  abstract removeChangeOrder(id): Observable<Object>;
  abstract refreshNeeded$;
}
