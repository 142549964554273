import { Observable } from 'rxjs';

export interface ProductsAndServices {
  title: string;
  category: string;
  price: string;
  description: string;
  quantity: string;
  supplier: string;
}

export abstract class ProductsAndServicesData {
  abstract getProductsAndServices(url): Observable<Object>;
  abstract addProductAndService(data);
  abstract editProductsAndServices(id, data);
  abstract deleteProductsAndServices(id);
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
}
