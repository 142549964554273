import { Observable } from 'rxjs';

export interface FranchiseeDetails {
  id: number;
  active: boolean;
  business_tax_number: number;
  email_verified: boolean;
  office_number: string;
  phone_number: string;
  address: string;
  address2: string;
  profile_pic_url: string;
  profile_picture: string;
  gst: number;
  user: [{
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    url: string;
    username: string;
  }];
  business_contact: [{
    email_address: string;
    phone_number: string;
    address2: string;
  }];
  emergency_contact: [{
      id: string;
      phone_number: string;
      email_address: string;
      name: string;
      address2: string;
  }];
  province: [{
    id: number;
    name: string;
    tax_type: string;
    pst: number;
    gst: number;
    hst: number;
  }];
}

export abstract class FranchiseeDetailsData {
  abstract getFranchiseeDetails(data): Observable<Object>;
}
