import { Observable } from 'rxjs';

export interface Marketing {
  id: number;
  author: number;
  tags: any;
  created: string;
  updated: string;
  file: string;
}

export abstract class MarketingData {
  abstract selectedTag: number;
  abstract marketingDocuments: any;
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent(msg);
  abstract getMarketingDocuments(url): Observable<Object>;
  abstract addMarketingDocuments(data, index);
  abstract deleteMarketingDocument(id);
}
