import { Observable } from 'rxjs';

export interface FranchiseeAgreementInfo {
  agreement_start_date: string;
  agreement_years: number;
  agreementend_date: string;
  status: string;
  uuid_key: number;
  franchisee: number;
  sales_person: string;
  monthly_costs: [{
    currency: string;
    description: string;
    franchisee: number;
    id: number;
    is_standard: boolean;
    price: string;
    title: string;
  }];
  startup_costs: [{
    currency: string;
    description: string;
    franchisee: number;
    id: number;
    is_standard: boolean;
    price: string;
    title: string;
  }];
}

export abstract class FranchiseAgreementData {
  abstract getFranchiseeAgreementInfo(data): Observable<Object>;
  abstract createFranchiseAgreement(data);
  abstract getSalesPerson(): Observable<Object>;
}
