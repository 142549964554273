import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { TaskManagerData } from '../data/task-manager';
import { UserService } from './users.service';
import { CurrentUser } from '../data/users';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends TaskManagerData {
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  private apiToken = '';
  private subject = new Subject<any>();

  constructor(private httpClient: HttpClient, private authService: NbAuthService) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  sendUpdateEvent() {
    this.subject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  getToDoLists(): Observable<any> {
    return this.httpClient.get(`${environment.apiBaseUrl}/operations/todolist/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getToDoListById(id): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/operations/todolist/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getToDoListByFranchiseeId(id): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/operations/todolist/?franchisee=${id}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getToDoListByFranchiseeIdAndPage(id, pageNumber): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/operations/todolist/?franchisee=${id}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getToDoListByFranchiseeIdAndNameAndPage(id, name, pageNumber): Observable<Object> {
    return this.httpClient.get<Object>(environment.apiBaseUrl + `/operations/todolist/?franchisee=${id}&search=${name}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }


  updateToDoListByFranchiseeId(id, todos): Observable<Object> {
    const todolist = this.httpClient.patch<Object>(environment.apiBaseUrl + `/operations/todolist/?franchisee=${id}`,
      todos,
      {
        headers: {
          'Authorization': 'Token ' + this.apiToken,
        },
      }).pipe(
        catchError(this.handleError),
      );
    return todolist;
  }

  addToDoList(data): Observable<Object> {
    const todo = this.httpClient.post<Object>(environment.apiBaseUrl + `/operations/todolist/`,
    data,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
    this.sendUpdateEvent();
    return todo;
  }

  updateToDoList(id, todo): Observable<Object> {
    const todolist = this.httpClient.put<Object>(environment.apiBaseUrl + `/operations/todolist/${id}/`,
    todo,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
    this.sendUpdateEvent();
    return todolist;
  }

  deleteToDoList(id): Observable<Object> {
    const todo = this.httpClient.delete<Object>(environment.apiBaseUrl + `/operations/todolist/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
    this.sendUpdateEvent();
    return todo;

  }

}
