import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DocumentData } from '../data/documents';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends DocumentData {
  private apiToken = '';
  private subject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  public selectedTag: number = 0;
  public documents: any = [];

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  getDocuments(url): Observable<Object> {
    return this.httpClient.get(url, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getDocumentsById(id): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/franchise/documents/${id}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  addDocuments(document: any, fileIndex: any = null) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient.post(`${environment.apiBaseUrl}/franchise/documents/`,
      document,
      options,
    ).subscribe(
      data => {
        const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Document successfully added.`,
          {
            status: 'success',
            icon: iconConfig,
          },
        );

        const ext = data['file'].split('.').pop();

        data['fileType'] = ext;
        this.documents.push(data);
        this.sendUpdateEvent(fileIndex);
      },
      err => {
        const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Error adding document.`,
          {
            status: 'danger',
            icon: iconConfig,
          },
        );

        this.handleError;
      },
    );
  }

  deleteDocument(id)  {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };
    this.httpClient.delete(`${environment.apiBaseUrl}/franchise/documents/${id}/`,
      options,
    ).subscribe(
      data => {
        const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Document successfully deleted.`,
          {
            status: 'success',
            icon: iconConfig,
          },
        );
        this.sendUpdateEvent(null);
      },
      err => {
        if (err) {
          const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
          this.toastrService.show(
            '',
            `Error delete document.`,
            {
              status: 'danger',
              icon: iconConfig,
            },
          );
        }
      },
    );
  }

  sendUpdateEvent(fileindex: any = null) {
    this.subject.next(fileindex);
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
