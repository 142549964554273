import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserData } from '../data/users';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService, NbTokenService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService extends UserData {
  private apiToken = '';

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }

    return throwError('Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private tokenService: NbTokenService,
    private router: Router,
  ) {
    super();

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.apiToken = token['token'];
      }
    });
  }

  getCurrentUser() {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    return this.httpClient
      .get(`${environment.apiBaseUrl}/users/me/`, options)
      .pipe(catchError(this.handleError));
  }

  logout() {
    if (this.apiToken === '') {
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    return this.httpClient
      .post(`${environment.apiBaseUrl}/secure/token/logout`, null, options)
      .subscribe(
        (data) => {
          localStorage.clear();
          this.tokenService.clear();
          this.router.navigateByUrl('/auth/login');
        },
        (err) => {
          this.handleError;
        },
      );
  }

}
