import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { InvoiceData } from '../data/invoice';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends InvoiceData {
  private apiToken = '';
  private productsAndServicesSubject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  getInvoice(url: string): Observable<Object> {
    return this.httpClient.get(url, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getInvoiceById(id): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/invoices/${id}/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getInvoiceByFranchiseeId(id, pageNumber): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/invoices/?franchisee=${id}&page=${pageNumber}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getInvoiceByFranchiseeIdAndNameAndPageNumber(id, name, pageNumber): Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/invoices/?franchisee=${id}&search=${name}&page=${pageNumber}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  addInvoice(invoice): Observable<Object> {
    return this.httpClient
      .post(`${environment.apiBaseUrl}/operations/invoices/`,
      invoice,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  editInvoiceById(id, invoice): Observable<Object> {
    return this.httpClient
      .patch(`${environment.apiBaseUrl}/operations/invoices/${id}/`,
      invoice,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  deleteInvoiceById(id): Observable<Object> {
    let invoice = this.httpClient
      .delete(`${environment.apiBaseUrl}/operations/invoices/${id}/`,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
      this.sendUpdateEvent();
      return invoice;
  }

  deletePaymentById(id): Observable<Object> {
    let payment = this.httpClient
      .delete(`${environment.apiBaseUrl}/operations/payment/${id}/`,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
      this.sendUpdateEvent();
      return payment;
  }

  sendUpdateEvent() {
    this.productsAndServicesSubject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.productsAndServicesSubject.asObservable();
  }

  getRecordPaymentByInvoiceId(id):Observable<Object> {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/operations/payment/?invoice=${id}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  addRecordPayment(payment): Observable<Object> {
    console.log(payment)
    return this.httpClient
      .post(`${environment.apiBaseUrl}/operations/payment/`,
      payment,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  addRecordPayment2(paymentForm): Observable<Object> {
    let payment = paymentForm.payments[paymentForm.payments.length - 1];
    return this.httpClient
      .post(`${environment.apiBaseUrl}/operations/payment/`,
      {
        client: paymentForm.client,
        invoice: paymentForm.invoice,
        payment_date: payment.payment_date,
        paid_via: payment.paid_via,
        payment_type: paymentForm.payment_type,
        amount: payment.amount,
        external_payment_id: paymentForm.external_payment_id,
        status: "succeeded",
      },
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  editRecordPaymentByInvoiceId(id, payment): Observable<Object> {
    return this.httpClient
      .patch(`${environment.apiBaseUrl}/operations/payment/${id}/`,
      {
        amount: payment.amount,
        paid_via: payment.paid_via,
        payment_date: payment.payment_date,
      },
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }



  addPaymentChange(payment): Observable<Object> {
    return this.httpClient
      .post(`${environment.apiBaseUrl}/operations/paymentchange/`,
      payment,
      {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }
}
