import {
  Injectable,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbAuthSimpleToken,
} from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { Observable } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService, LayoutService } from './utils';
import { UserData } from './data/users';
import { UserService } from './api/users.service';
import { FranchiseAgreementData } from './data/franchise-agreement';
import { FranchiseeAgreementService } from './api/franchise-agreement.service';
import { ClientData } from './data/clients';
import { ClientService } from './api/clients.service';
import { FranchisorReportData } from './data/franchisor-report';
import { FranchisorReportService } from './mock/franchisor-report.service';
import { FranchiseeData } from './data/franchisees';
import { FranchiseeService } from './api/franchisees.service';
import { AuthGuard } from './utils/auth-guard.service';
import { RoleGuard } from './utils/role-guard.service';
import { FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../environments/environment';
import { EstimateData } from './data/estimates';
import { EstimateService } from './api/estimates.service';
import { ProductsAndServicesData } from './data/products-and-services';
import { ProductsAndServicesService } from './api/products-and-services.service';
import { EmployeeData } from './data/employees';
import { EmployeeService } from './api/employees.service';
import { MockDataModule } from './mock/mock-data.module';
import { APIDataModule } from './api/api-data.module';
import { catchError, map } from 'rxjs/operators';
import { DocumentTagData } from './data/document-tags';
import { DocumentTagsService } from './api/document-tags.service';
import { DocumentData } from './data/documents';
import { DocumentService } from './api/documents.service';
import { MarketingTagData } from './data/marketing-tags';
import { MarketingTagsService } from './api/marketing-tags.service';
import { MarketingData } from './data/marketing';
import { MarketingService } from './api/marketing.service';
import { FranchiseeDetailsService } from './api/franchisee-details.service';
import { FranchiseeDetailsData } from './data/franchisee-details';
import { StartupMonthlyCostsData } from './data/startup-monthly-costs';
import { StartupMonthlyCostsService } from './api/startup-monthly-costs.service';
import { ProjectStatusData } from './data/project-status';
import { ProjectStatusService } from './api/project-status.service';
import { SalesData } from './data/sales';
import { SalesService } from './api/sales.service';
import { InvoiceData } from './data/invoice';
import { InvoiceService } from './api/invoice.service';
import { TaskManagerData } from './data/task-manager';
import { TaskService } from './api/task-manager.service';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { DashboardService } from './api/dashboard.service';

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: FranchiseAgreementData, useClass: FranchiseeAgreementService },
  { provide: ClientData, useClass: ClientService },
  { provide: FranchisorReportData, useClass: FranchisorReportService },
  { provide: FranchiseeData, useClass: FranchiseeService },
  { provide: FranchiseeDetailsData, useClass: FranchiseeDetailsService },
  { provide: EstimateData, useClass: EstimateService },
  { provide: ProductsAndServicesData, useClass: ProductsAndServicesService },
  { provide: EmployeeData, useClass: EmployeeService },
  { provide: DocumentTagData, useClass: DocumentTagsService },
  { provide: DocumentData, useClass: DocumentService },
  { provide: MarketingData, useClass: MarketingService },
  { provide: MarketingTagData, useClass: MarketingTagsService },
  { provide: StartupMonthlyCostsData, useClass: StartupMonthlyCostsService },
  { provide: ProjectStatusData, useClass: ProjectStatusService },
  { provide: SalesData, useClass: SalesService },
  { provide: InvoiceData, useClass: InvoiceService },
  { provide: TaskManagerData, useClass: TaskService },
  { provide: DashboardComponent, useClass: DashboardService },
];

@Injectable()
export class NbSimpleRoleProvider implements NbRoleProvider {
  constructor(private userService: UserService) { }

  getRole(): Observable<string> {
    return this.userService.getCurrentUser().pipe(
      map((data) => {
        if (data['role'] === 'EMPLOYEE' && data['job_title'].toLowerCase() === 'manager') {
          return ['EMPLOYEE', 'FRANCHISEE']
        } else {
          return data['role'] ? data['role'] : 'GUEST';
        }
      }),
      catchError((err) => 'GUEST'),
    );
  }

}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...APIDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.apiBaseUrl,
        login: {
          endpoint: '/secure/token/login',

        },
        register: {
          endpoint: '/',
        },
        token: {
          class: NbAuthSimpleToken,
          key: 'auth_token',
        },
      }),
    ],
    forms: {},
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      SUPERUSER: {
        create: '*',
        edit: '*',
        remove: '*',
        view: '*',
      },
      EMPLOYEE: {
        view: [
          'dashboard',
          'onboarding',
          'training',
          'knowledge-centre',
          'policy-manual',
          'employee-profile',
        ],
      },
      FRANCHISEE: {
        view: [
          'dashboard',
          'revivify-painting',
          'franchise-details',
          'franchise-agreement',
          'documents',
          'billing',
          'sales',
          'estimates',
          'clients',
          'invoices',
          'products-and-services',
          'quotes',
          'marketing',
          'accounting',
          'project-status',
          'employees',
          'task-manager',
          'revivify-corporate',
        ],
      },
      STAFF: {
        view: [
          'dashboard',
          'documents',
          'marketing',
          'reports',
          'franchisees',
          'settings',
        ],
      },
      FRANCHISOR: {
        view: [

          'dashboard',
          'documents',
          'marketing',
          'billing',
          'franchisees',

        ],
      },
      GUEST: {},
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  AuthGuard,
  RoleGuard,
  FormBuilder,
  NbToastrService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
