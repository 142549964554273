import { Observable, Subject, throwError, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, expand, map, reduce  } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { Billing, BillingData } from '../data/billing';
import { DashboardData } from '../data/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends DashboardData {
  private apiToken = '';
  private subjec = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  getFranchisorReport(): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/franchise/dashboard/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchisorReportWithPeriod(period): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/franchise/dashboard/?${period}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeReport(id): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/franchise/franchisee-dashboard/${id}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeReportWithPeriod(id, period): Observable<Object> {
    console.log(period)
    return this.httpClient
    .get(`${environment.apiBaseUrl}/franchise/franchisee-dashboard/${id}?${period}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeReportList(): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/accounts/franchiseesectiondashboard/`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeReportListbyPeriod(period): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/accounts/franchiseesectiondashboard/?${period}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeReportListByPage(pageNumber): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/accounts/franchiseesectiondashboard/?&page=${pageNumber}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeInvoices(id, page: number = 1): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/operations/invoices/?franchisee=${id}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseeReportListByPageAndPeriod(pageNumber, period): Observable< Object > {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/accounts/franchiseesectiondashboard/?page=${pageNumber}&${period}`, {
      headers: {
        Authorization: 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError)
    );
  }

  getFranchiseeInvoicesReport(id, period): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/operations/invoicesreport/?franchisee=${id}&${period}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFranchiseePaymentsReport(id, period ): Observable<Object> {
    return this.httpClient
    .get(`${environment.apiBaseUrl}/operations/payment/?invoice__franchisee=${id}&${period}`, {
        headers: {
          Authorization: 'Token ' + this.apiToken,
        },
      })
      .pipe(catchError(this.handleError));
  }

  
  // getFranchiseeInvoices(id, page: number = 1): Observable<any[]> {
  //   return this.httpClient
  //     .get(`${environment.apiBaseUrl}/operations/invoices/?franchisee=${id}&page=${page}`, {
  //       headers: {
  //         Authorization: 'Token ' + this.apiToken,
  //       },
  //     })
  //     .pipe(
  //       catchError(this.handleError),
  //       expand((data: any) => {
  //         // Check if data is not null and has a 'next' property
  //         return data && data.next ? this.getFranchiseeInvoices(id, page + 1) : of(null);
  //       }),
  //       reduce((acc, data) => {
  //         // Accumulate the results
  //         return data ? acc.concat(data.results) : acc;
  //       }, [])
  //     );
  // }

  sendUpdateEvent() {
    this.subjec.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.subjec.asObservable();
  }

}
