import { throwError, Observable, Subject} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { FranchiseAgreementData } from '../data/franchise-agreement';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class FranchiseeAgreementService extends FranchiseAgreementData {
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  private apiToken: string;
  private sendUpdate = new Subject<any>();


  constructor(private httpClient: HttpClient, private authService: NbAuthService,
    private toastrService: NbToastrService) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });

  }

  getFranchiseeAgreementInfo(franchiseeId) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    return this.httpClient.get(environment.apiBaseUrl + `/franchise/franchiseeagreement/${franchiseeId}/`,
    options,
    ).pipe(
      catchError(this.handleError),
    );
  }

  createFranchiseAgreement(data) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
   this.httpClient.post(`${environment.apiBaseUrl}/franchise/franchiseeagreement/`,
      data,
      options,
    ).pipe(
      catchError(this.handleError),
    ).subscribe(fagreement => {
      if (fagreement) {
      const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
      this.toastrService.show(
        '',
        `Franchisee successfully added.`,
        {
          status: 'success',
          icon: iconConfig,
        },
      );
      this.sendUpdateEvent();
      }
    },
    err => {
      const iconConfig: NbIconConfig = {
        icon: 'alert-circle-outline',
        pack: 'eva',
      };

      this.toastrService.show(
        '',
        err, {
          status: 'danger',
          icon: iconConfig,
        },
      );
    });
  }

  getSalesPerson(): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });
    const options = {
      headers,
    };
    return this.httpClient.get(environment.apiBaseUrl + `/accounts/salesperson/`,
    options,
    ).pipe(
      catchError(this.handleError),
    );
  }

  sendUpdateEvent() {
    this.sendUpdate.next();
  }


}
