import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { EmployeeData } from '../data/employees';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends EmployeeData {
  private apiToken = '';
  private subject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  sendUpdateEvent() {
    this.subject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  getEmployees(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/employee/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getEmployeeById(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/employee/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getEmployeeByFranchiseeId(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/employee/?franchisee=${id}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getEmployeeByFranchiseeIdandPage(id, pageNumber): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/employee/?franchisee=${id}&page=${pageNumber}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  addEmployee(employee: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient.post(`${environment.apiBaseUrl}/accounts/add/employee`,
      employee,
      options,
    ).subscribe(
      data => {
        const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Employee successfully added.`,
          {
            status: 'success',
            icon: iconConfig,
          },
        );

        this.sendUpdateEvent();
      },
      err => {
        const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Error adding employee.`,
          {
            status: 'danger',
            icon: iconConfig,
          },
        );

        this.handleError;
      },
    );
  }

  updateEmployee(id, employee): Observable<Object> {
    return this.httpClient.put(`${environment.apiBaseUrl}/accounts/employee/${id}/`,
    employee,
    {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getOnboardingSteps(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/employeeorientationchecklist/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }
}
