import { Observable } from 'rxjs';

export interface Clients {
  id: number;
  date_acquired: string;
  email_address: string;
  first_name: string;
  last_name: string;
  office_number: string;
  phone_number: string;
  stage: string;
  sales_set: any;
  address: [{
    city: string;
    id: number;
    latitude: string;
    longitude: string;
    province: string;
    street_name: string;
    street_number: string;
    unit_number: string;
  }];
  address2: string;
}

export abstract class ClientData {
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
  abstract getClients(): Observable<Clients[]>;
  abstract getClientById(id): Observable<Object>;
  abstract getClientByFranchiseeId(id): Observable<Object>;
  abstract getClientByFranchiseeIdAndPage(id, pageNumber): Observable<Object>;
  abstract getClientByFranchiseeIdAndNameAndPage(id, name, pageNumber): Observable<Object>;
  abstract deleteClient(id): Observable<Object>;
  abstract addClient(id): Observable<Object>
  abstract editClientById(client, id): Observable<Object>;
  abstract getClientCount(id):Observable<Object>;
  abstract refreshNeeded$;
}
