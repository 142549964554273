import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { ClientService } from './clients.service';
import { EmployeeService } from './employees.service';
import { DocumentTagsService } from './document-tags.service';
import { DocumentService } from '../api/documents.service';
import { MarketingTagsService } from './marketing-tags.service';
import { MarketingService } from './marketing.service';
import { SalesService } from './sales.service';

const SERVICES = [
  UserService,
  EmployeeService,
  DocumentTagsService,
  DocumentService,
  MarketingTagsService,
  MarketingService,
  ClientService,
  SalesService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class APIDataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: APIDataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
