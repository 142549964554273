
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FranchisorReport, FranchisorReportData } from '../../../@core/data/franchisor-report';
import { Subject } from 'rxjs';
import { DashboardService } from '../../../@core/api/dashboard.service';
import { UserService } from '../../../@core/api/users.service';
import { takeUntil } from 'rxjs/operators';
import { CurrentUser } from '../../../@core/data/users';
import { Dashboard } from '../../../@core/data/dashboard';
import { ClientData } from '../../../@core/data/clients';
import { Franchisees } from '../../../@core/data/franchisees';

@Component({
  selector: 'ngx-reports',
  styleUrls: ['./reports.component.scss'],
  templateUrl: './reports.component.html',
})
export class ReportsComponent implements OnInit, OnChanges {
  @Input() period = '';
  report: Dashboard;
  user: CurrentUser;
  clientCount: string;
  franchisee: Franchisees;
  private destroy$: Subject < void > = new Subject < void > ();
  constructor(private dashboardService: DashboardService,
    private userService: UserService,
    private clientService: ClientData) { }

  ngOnInit() {
    // 1. Get current user
    this.userService.getCurrentUser()
    .pipe(takeUntil(this.destroy$))
    .subscribe((user: any) => {
      this.user = user;
      if(this.user){
        if(this.user.role === 'FRANCHISOR'){
          this.getFranchisorDashboardReport();
        }
        else{
          this.getFranchiseeDashboardReport();
        }
      }
    });
  }

  ngOnChanges() {
    if(this.user){
      if(this.user.role === 'FRANCHISOR'){
        this.getFranchisorDashboardReport();
      }
      else{
        this.getFranchiseeDashboardReport();
      }
    }
  }

  getFranchisorDashboardReport(){
    this.dashboardService.getFranchisorReportWithPeriod(this.period)
    .pipe()
    .subscribe((data: Dashboard) => {
      this.report = data;
      this.clientCount = data['leads'];
    });
  }

  getFranchiseeDashboardReport() {
    this.dashboardService.getFranchiseeReportWithPeriod(this.user['franchisee id'], this.period)
      .pipe()
      .subscribe((data: Dashboard) => {
        this.clientCount = data['incoming_leads'];
        this.report = data;
      });
  }

}
