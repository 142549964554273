import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProductsAndServicesData } from '../data/products-and-services';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class ProductsAndServicesService extends ProductsAndServicesData {
  private apiToken = '';
  private productsAndServicesSubject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  getProductsAndServices(url: string): Observable<Object> {
    return this.httpClient.get(url, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  editProductsAndServices(id, productandservice) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient.put(`${environment.apiBaseUrl}/operations/toolinventory/` + `${id}` + `/`,
      productandservice,
      options,
    ).subscribe(
      data => {
        if (data) {
          const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
          this.toastrService.show(
            '',
            `Tools & Equipment inventory is successfully edited.`,
            {
              status: 'success',
              icon: iconConfig,
            },
          );

          this.sendUpdateEvent();
        }
      },
      err => {
        if (err) {
          const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
          this.toastrService.show(
            '',
            `Error adding Tools & Equipment inventory.`,
            {
              status: 'danger',
              icon: iconConfig,
            },
          );
        }
      },
    );
  }


  addProductAndService(productandservice: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient.post(`${environment.apiBaseUrl}/operations/toolinventory/`,
      productandservice,
      options,
    ).subscribe(
      data => {
        if (data) {
          const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
          this.toastrService.show(
            '',
            `Tools & Equipment inventory successfully added.`,
            {
              status: 'success',
              icon: iconConfig,
            },
          );

          this.sendUpdateEvent();
        }
      },
      err => {
        const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Error adding Tools & Equipment inventory.`,
          {
            status: 'danger',
            icon: iconConfig,
          },
        );

        this.handleError;
      },
    );
  }

  deleteProductsAndServices(id) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient.delete(`${environment.apiBaseUrl}/operations/toolinventory/` + `${id}` + `/`,
      options,
    ).subscribe(
      data => {
        const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Tools & Equipment inventory successfully deleted.`,
          {
            status: 'success',
            icon: iconConfig,
          },
        );

        this.sendUpdateEvent();
      },
      err => {
        if (err) {
          const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
          this.toastrService.show(
            '',
            `Error deleting Tools & Equipment inventory.`,
            {
              status: 'danger',
              icon: iconConfig,
            },
          );
        }
      },
    );
  }

  sendUpdateEvent() {
    this.productsAndServicesSubject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.productsAndServicesSubject.asObservable();
  }
}
