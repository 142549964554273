import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { FranchiseeData } from '../data/franchisees';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FranchiseeService extends FranchiseeData {
  private subject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  private apiToken: any;

  constructor(private httpClient: HttpClient, private authService: NbAuthService) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  getFranchisees() {

    return this.httpClient.get(environment.apiBaseUrl + `/accounts/franchisee/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

  }

  getFranchiseeById(id): Observable<Object> {

    return this.httpClient.get(environment.apiBaseUrl + `/accounts/franchisee/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

  }

  createFranchisee(data: any): Observable<Object> {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    const franchisees =  this.httpClient.post(`${environment.apiBaseUrl}/accounts/franchisee/`,
      data,
      options,
      ).pipe(
        catchError(this.handleError),
      );

      this.sendUpdateEvent();
      return franchisees;

  }

  updateFranchisees(data) {
    // this.franchisees.push(data);
  }

  sendUpdateEvent() {
    this.subject.next();
  }

  getProvinceList(): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/generic/province/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  getProvinceById(id): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/generic/province/${id}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }
}
