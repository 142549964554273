import { Component, OnInit } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { UserService } from '../../@core/api/users.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentUser } from '../../@core/data/users';
import { Router } from '@angular/router';
import { NbCalendarRange, NbDateService } from '@nebular/theme';

@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit{
 
  
  role: string;
  loaded:boolean;
  range: NbCalendarRange<Date>;
  startDate: string;
  endDate: string;
  selectedPeriod: string;

  private destroy$: Subject < void > = new Subject < void > ();
  constructor(public accessChecker: NbAccessChecker,
    private userService: UserService,
    private route: Router, 
    protected dateService: NbDateService<Date>) {
      this.range = {
        start: this.dateService.addDay(this.monthStart, 3),
        end: this.dateService.addDay(this.monthEnd, -3),
      };
     }

     get monthStart(): Date {
      return this.dateService.getMonthStart(new Date());
    }
  
    get monthEnd(): Date {
      return this.dateService.getMonthEnd(new Date());
    }

  ngOnInit(){
    this.loaded = false;
    this.userService.getCurrentUser()
    .pipe(takeUntil(this.destroy$))
    // Current user
    .subscribe((user: CurrentUser) => {
      this.role = user.role;

      this.loaded = true;
      if(this.role === 'EMPLOYEE'){
        this.route.navigateByUrl(`/pages/onboarding`);
      }

      
      

    }); // End Current User
  }

  getRangeDate(event) {
    if(event.start && event.end) {
      this.startDate = `${event.start.getFullYear()}-${(event.start.getMonth() + 1).toString().padStart(2,"0")}-${event.start.getDate().toString().padStart(2,"0")}`;
      this.endDate = `${event.end.getFullYear()}-${(event.end.getMonth() + 1).toString().padStart(2,"0")}-${event.end.getDate().toString().padStart(2,"0")}`

      this.selectedPeriod = `start-date=${this.startDate}&end-date=${this.endDate}`;
    }
  }

}
