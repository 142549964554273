import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { MarketingTagData } from '../data/marketing-tags';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketingTagsService extends MarketingTagData {
  private apiToken = '';
  private subject = new Subject<any>();
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  sendUpdateEvent() {
    this.subject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  getMarketingTags(url): Observable<Object> {
    return this.httpClient.get(url, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }

  addTag(tag: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + this.apiToken,
    });

    const options = {
      headers,
    };

    this.httpClient.post(`${environment.apiBaseUrl}/franchise/tags/`,
      tag,
      options,
    ).subscribe(
      data => {
        const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Tag successfully added.`,
          {
            status: 'success',
            icon: iconConfig,
          },
        );

        this.sendUpdateEvent();
      },
      err => {
        const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
        this.toastrService.show(
          '',
          `Error adding tag.`,
          {
            status: 'danger',
            icon: iconConfig,
          },
        );

        this.handleError;
      },
    );
  }
}
