import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { FranchiseeDetailsData } from '../data/franchisee-details';
import { UserService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class FranchiseeDetailsService extends FranchiseeDetailsData {
  private apiToken = '';
  user: UserService;
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    private authService: NbAuthService,
  ) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  getFranchiseeDetails(userId): Observable<Object> {
    return this.httpClient.get(`${environment.apiBaseUrl}/accounts/franchisee/${userId}/`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );

  }



}
