import { Observable } from 'rxjs';

export interface DocumentTag {
  id: number;
  author: number;
  documents_set: any;
  created: string;
  updated: string;
  tag_name: string;
}

export abstract class DocumentTagData {
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
  abstract getDocumentTags(url): Observable<Object>;
  abstract addTag(data);
}
