import { Observable } from 'rxjs';

export interface ToDo {
  id: number;
  franchisee: number;
  employee: number;
  title: string;
  description: string;
  due_date: string;
  status: string;
}


export abstract class TaskManagerData {
  abstract getUpdateEvent(): Observable<Object>;
  abstract sendUpdateEvent();
  abstract getToDoLists(): Observable<any>;
  abstract getToDoListById(id): Observable<Object>;
  abstract getToDoListByFranchiseeId(id): Observable<Object>;
  abstract updateToDoListByFranchiseeId(id, data): Observable<Object>;
  abstract addToDoList(data): Observable<Object>;
  abstract updateToDoList(id, data): Observable<Object>;
  abstract deleteToDoList(id): Observable<Object>;
}
