import { Observable } from 'rxjs';

export interface FranchisorReport {
  sales: string;
  revenue: string;
}

export abstract class FranchisorReportData {
  abstract getFranchisorReportData(): Observable<FranchisorReport>;
}
