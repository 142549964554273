import { Observable } from 'rxjs';

export interface Dashboard {
  sales: number;
  revenue: number;
  evaluations_completed: number;
  quotes_sent: number;
  projects_closed: number;
  projects_completed: number;
  signed_contracts: number;
}

export abstract class DashboardData {
  abstract getFranchisorReport(): Observable<Object>;
  abstract getFranchiseeReport(id): Observable<Object>;
  abstract getFranchiseeReportList(): Observable<Object>;
}
