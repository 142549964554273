<img src="/assets/images/logo.png" class="img-fluid logo" />
<h1 id="title" class="title">Sign In</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-username">Username:</label>
    <input nbInput fullWidth [(ngModel)]="user.username" #username="ngModel" name="username" id="input-username"
      placeholder="Username" autofocus>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Password:</label>
    <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password"
      id="input-password" placeholder="Password"
      [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
        Password should contains
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>


  <button nbButton fullWidth status="success" [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted" [nbSpinner]="submitted">
    Sign In
  </button>
</form>
