import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SalesData } from '../data/sales';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SalesService extends SalesData {
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  private apiToken = '';

  private subject = new Subject<any>();

  constructor(private httpClient: HttpClient, private authService: NbAuthService) {
    super();

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.apiToken = token['token'];
        }
      });
  }

  sendUpdateEvent() {
    this.subject.next();
  }

  getUpdateEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  getSalesById(id): Observable<Object[]> {
    return this.httpClient.get<Object[]>(environment.apiBaseUrl + `/operations/sales/${id}`, {
      headers: {
        'Authorization': 'Token ' + this.apiToken,
      },
    }).pipe(
      catchError(this.handleError),
    );
  }
}
